<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Ceesp">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody id="tbody_Ceesp"> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <template v-for="(item, key) in data.data.data">
                            <tr v-for="(sub_item, sub_key) in max_row(item.ceesp)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)">
                                <td >
                                    <label class="table-check" v-if="sub_key === 0">
                                        <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                        <span class="checkbox-table"></span>
                                    </label>
                                </td>
                                <td>
                                    <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                         <div class="icon-fiche" v-if="sub_key === 0"></div>
                                    </router-link>
                                    <div v-if="sub_key === 0 && isAdmin()">
                                        <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                            <em class="icon-edit"></em>
                                        </a>
                                    </div>
                                </td>
                                <template v-for="(column) in data.column" :key="column.code" >


                                    <td v-if="column.Status && column.code == 'ceesp_001'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="ema_id(item.links)"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_002'" :code="column.code">
                                        <template v-if="sub_key === 0">{{item.drug_id}}</template>
                                    </td>
                                    <!-- <td v-else-if="column.Status && column.code == 'ceesp_003'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="icon_link(item.drug_id, 'ceesp')"></span>
                                    </td> -->
                                    <td v-else-if="column.Status && column.code == 'ceesp_004'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.category)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_005'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.dci_new)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_038'" :code="column.code">
                                        <span v-if="sub_key === 0"  v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_006'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="column_status(item)"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_007'" :code="column.code">
                                        <span v-if="sub_key === 0">{{check_empty(DDMMMYY(item.ceesp.publication_date))}}</span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_008'" :code="column.code">
                                        <template  v-if="sub_key === 0">{{check_empty(item.name)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_009'" :code="column.code">
                                        <template  v-if="sub_key === 0">{{check_empty(item.dci)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_010'" :code="column.code">                                
                                        <template  v-if="sub_key === 0">{{check_empty(item.firm)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_011'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="check_empty(array(item.atc))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_012'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_013'" :code="column.code">
                                        <Readmore v-if="sub_key === 0 && $i18n.locale == 'fr'"  :longText="item.ceesp.subject_economic_evaluationfr"/>
                                        <Readmore v-else-if="sub_key === 0 && $i18n.locale != 'fr'"  :longText="item.ceesp.subject_economic_evaluationen"/>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_014'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.ceesp.impact_significatif, 'yes_no_')}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_015'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.ceesp.conclusion, 'conclusion_')}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_044'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.ceesp.eco_effi)}}</template>
                                    </td>

                                    <td v-else-if="column.Status && column.code == 'ceesp_016'" :code="column.code">
                                        <p style="margin: 0;" v-if="item.ceesp.ceesp_titleappreserve[sub_key]"> 
                                            <span v-if="$i18n.locale == 'fr'" v-html="check_empty(array_ceesp(item.ceesp.ceesp_titleappreserve[sub_key], 'titlefr'))"></span> 
                                            <span v-else v-html="check_empty(array_ceesp(item.ceesp.ceesp_titleappreserve[sub_key], 'titleen'))"></span> </p>
                                        <p style="margin: 0;"> <span v-html="check_empty(array_ceesp(item.ceesp.ceesp_titleappreserve[sub_key], 'appreciation'))"></span> </p>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_017'" :code="column.code">                                
                                        <Readmore v-if="$i18n.locale == 'fr'" :longText="array_ceesp(item.ceesp.ceesp_titleappreserve[sub_key] ,'reversefr')" />
                                        <Readmore v-else :longText="array_ceesp(item.ceesp.ceesp_titleappreserve[sub_key] ,'reverseen')" />
                                    </td>                            
                                    <td v-else-if="column.Status && column.code == 'ceesp_018'" :code="column.code">
                                        <template v-if="sub_key === 0 && item.ceesp.additional_data != 0">{{check_empty_with_locale(item.ceesp.additional_data)}}</template>
                                        <template v-else-if="sub_key === 0 && item.ceesp.additional_data == 0">-</template>
                                    </td>                            
                                    <td v-else-if="column.Status && column.code == 'ceesp_019'" :code="column.code">
                                        <template v-if="sub_key === 0 && item.ceesp.budget_impact != 0"> {{check_empty_with_locale(item.ceesp.budget_impact)}} </template>
                                        <template v-else-if="sub_key === 0 && item.ceesp.budget_impact == 0">-</template>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'ceesp_020'" :code="column.code">
                                        <span v-html="array_ceesp2(item.ceesp.evaluation_economic[sub_key], 'evaluation_economic_economic_analysis', 'comparator')"></span>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'ceesp_021'" :code="column.code">
                                        <span v-html="statusEvaluationEconomic(item.ceesp.evaluation_economic, sub_key, 'evaluation_economic_economic_analysis')"></span>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'ceesp_022'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.ceesp.iab_requested, 'yes_no_')}}</template>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'ceesp_023'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.ceesp.incidence_healtcare,'yes_no_')}}</template>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'ceesp_024'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.ceesp.turnover_20,'yes_no_')}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_025'" :code="column.code">
                                        <span  v-if="sub_key === 0" v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_026'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.specificity)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_027'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.administration)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_028'" :code="column.code">
                                        <Readmore v-if="sub_key === 0" :longText="item.rationale_and_comments_html_en" />
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_029'" :code="column.code">
                                        <span v-if="sub_key === 0 && $i18n.locale == 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                        <span v-else-if="sub_key === 0 && $i18n.locale != 'fr'" v-html="check_empty(array(item.classification_en))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_030'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty_with_locale(item.mk_orphelin)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_031'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_032'" :code="column.code">
                                        <Readmore v-if="sub_key === 0" :longText="CeespReserve(item.ceesp.ceesp_reserve)"/>
                                    </td>
                                    <template v-else-if="column.Status && column.code == 'ceesp_033'" :code="column.code">
                                        <td :key="column.code+'_034'" :code="'ceesp_034'">
                                            <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.hta_process_date))}}</template>
                                        </td>
                                        <td :key="column.code+'_035'" :code="'ceesp_035'">
                                            <span v-if="sub_key === 0" v-html="hta_process_status(item)"></span>
                                        </td>
                                        <td :key="column.code+'_036'" :code="'ceesp_036'"> 
                                            <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.rembursement_process_date))}}</template>
                                        </td>
                                        <td :key="column.code+'_037'" :code="'ceesp_037'">
                                            <span v-if="sub_key === 0"  v-html="rembursement_status(item.reimbursement_decision)"></span>
                                        </td>
                                    </template>
                                    <!-- <template v-else-if="column.Status && column.code == 'ceesp_038'" :code="column.code">
                                        <td :key="column.code+'_039'" :code="'ceesp_039'">
                                            <span v-if="sub_key === 0" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                        </td>
                                        <td :key="column.code+'_040'" :code="'ceesp_040'">
                                            <span  v-if="sub_key === 0" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>
                                        </td>
                                        <td :key="column.code+'_041'" :code="'ceesp_041'">
                                            <span v-if="sub_key===0">                                                        
                                                {{check_empty(item.ma_date &&  item.rembursement_process_date && datediff(item.ma_date,item.rembursement_process_date) > 0 ?  datediff(item.ma_date, item.rembursement_process_date) :'' }}
                                            </span>
                                        </td>
                                    </template> -->
                                     <td v-else-if="column.Status && column.code == 'ceesp_042'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="check_empty(DDMMMYY(item.ceesp.date))"></span>
                                    </td>
                                     <td v-else-if="column.Status && column.code == 'ceesp_043'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="check_empty(DDMMMYY(item.ceesp.date_avis_guidance))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_045'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="trs_id(item.links)"></span>
                                    </td>
                                </template>

                            </tr>
                        </template>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div> 
        <!-- <ModalHTAstatus :item="item_hta_status"/> -->
        <ModalEssaisClinique :id="drug_id" agency="Ceesp"/>       
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
// import ModalHTAstatus from '@/components/result/modal_hta_status.vue'
import { isAdmin, column_status, hta_process_status, hta_process_status_2, rembursement_status, trs_id,
        rembursement_status_2, overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY, 
        check_empty, nct_number, treatment_line, datediff, array, odd_even_border, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Ceesp",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        // ModalHTAstatus,
        ModalEssaisClinique
    },
    data : function() {
        return { 
            // item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        odd_even_border,
        check_empty_with_locale,
        max_row(param){
            let temp = []
            temp.push(1)
            if(param.evaluation_economic){
                temp.push(Object.keys(param.evaluation_economic).length)
            }
            if(param.ceesp_titleappreserve){
                temp.push(Object.keys(param.ceesp_titleappreserve).length)
            }
            return Math.max(...temp)
        },
        array_ceesp : function(param ,index){
            if(param){
                return param[index]
            }
            else
                return ''
        },
        array_ceesp2 : function(param, foreign, index){
            let val = ''
            let val1 = ''
            if(this.$i18n.locale != 'fr'){
                index = index+'_en'
            }
            for(const key in param){
                if(key === foreign)
                    val1 = param[foreign]
                for(const key in val1){ 
                    if(val1){
                        val1 = val1[key]
                        if(val1 && val1[index]){ val = val1[index]}
                    }
                }
            }
            val = this.check_empty(val)
            return val
        },
        statusEvaluationEconomic(param, key, foreign){
            let html = '', val2 = '', val_icer_type = '', val_icur_gbp = '', val_icer_gbp = '', 
                val_icur_range_gbp = '', val_icer_range_gbp = ''
            let word = this.$t("per QALY gained")
            if (param[key] && param[key][foreign] && param[key][foreign][0]) { 
                val2 = param[key][foreign][0]

                if(val2.icur_gbp)
                    val_icur_gbp = val2.icur_gbp
                else if(val2.icur_gbp_en) 
                    val_icur_gbp = val2.icur_gbp_en
                if(val2.icer_gbp) 
                    val_icer_gbp = val2.icer_gbp
                else if(val2.icer_gbp_en)  
                    val_icer_gbp = val2.icer_gbp_en

                if(this.$i18n.locale != 'fr'){
                    val_icer_type = val2.icer_type_en ? val2.icer_type_en : ''
                    val_icur_range_gbp = val2.icur_range_gbp_en ? val2.icur_range_gbp_en : ''
                    val_icer_range_gbp = val2.icer_range_gbp_en ? val2.icer_range_gbp_en : ''
                }else{
                    val_icer_type = val2.icer_type ? val2.icer_type : ''
                    val_icur_range_gbp = val2.icur_range_gbp ? val2.icur_range_gbp : ''
                    val_icer_range_gbp = val2.icer_range_gbp ? val2.icer_range_gbp : ''
                }

                if(param.length > 1){
                  if (param[key].type_of_economic_analysis && param[key].type_of_economic_analysis == 'cua') {
                      if(val_icur_gbp) { html +=  val_icur_gbp + " " + word }   
                  } else if (param[key].type_of_economic_analysis && param[key].type_of_economic_analysis == 'cea') { 
                      if(val_icer_gbp) {html += val_icer_gbp + " " + val_icer_type }
                  } 
                }else{
                  if (param[key].type_of_economic_analysis && param[key].type_of_economic_analysis == 'cua') {
                      if(val_icur_gbp) { html +=  val_icur_gbp + " " + word }   
                      if(val_icur_range_gbp) { html +=  val_icur_range_gbp + " " + word }   
                  } else if (param.type_of_economic_analysis && param.type_of_economic_analysis == 'cea') { 
                      if(val_icer_gbp) {html += val_icer_gbp + " " + val_icer_type }
                      if(val_icer_range_gbp) {html += val_icer_range_gbp + " " + val_icer_type }
                  } 
                }  
            }
            html = this.check_empty(html)
            return html
        },
        isAdmin,
        ema_id,
        trs_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        CeespReserve(param){
            let html = ''
            let sub = {}
            let sub_value = ''
            if (param.length > 0) { 
                html += '<table class="table vertical-md" style=" min-width: 100% !important;">'
                        + '<thead>'
                        + '<tr>'
                        +    '<th width="70%">' + this.$t('Libellé de la réserve') + '</th>'
                        +    '<th width="10%">-</th>'
                        +    '<th width="10%">+</th>'
                        +    '<th width="10%">++</th>'
                        + '</tr>'
                        + '</thead>'
                        + '<tbody>'
                for(const key in param){
                    if(param[key]){
                        let val = param[key]
                        if(val.type !== 'CeespReserve'){
                            if(!sub[val.type]){
                                sub[val.type] = []
                            } 
                            if(val.text && this.$i18n.locale == 'fr'){
                                sub[val.type].push({"text":val.text , "item":val.item})
                            }else if(val.text_en && this.$i18n.locale == 'en'){
                                sub[val.type].push({"text":val.text_en , "item":val.item})
                            }
                                
                        }
                    }
                }
                for(const key in sub){
                        html += '<tr>'
                            +    '<td class="text-left" colspan="4" style="border-top: 1px solid #ddd !important;"><strong>' + this.$t('ceesp.'+ key) + '</strong></td>'
                            +'</tr>'
                        sub_value = sub[key]
                    for(const index in sub_value){
                        html += '<tr>'
                        html += '<td class="text-left" style="border-top: 1px solid #ddd !important;">' + sub_value[index].text + '</td>'
                        html += '<td class="text-center" style="border-top: 1px solid #ddd !important;">'  
                                if(sub_value[index].item == "-") html += "-" 
                                else html += ""  
                        html += '</td>'
                        html += '<td class="text-center" style="border-top: 1px solid #ddd !important;">' 
                                if(sub_value[index].item == "+") html += "+" 
                                else html += ""  
                        html += '</td>'
                        html += '<td class="text-center" style="border-top: 1px solid #ddd !important;">'
                                if(sub_value[index].item == "++") html += "++" 
                                else html += ""
                        html += '</td>'
                        html += '</tr>'
                        
                    }
                }
                html += '</tbody>' + '</table>' 
            }
            return html           
        },
        
        column_status,
        hta_process_status,
        rembursement_status,
        hta_process_status_2,
        rembursement_status_2,       
        array,
        getResults(page = 1) {
            console.log(page)
        },
        // hta_status_modal(item){
        //     this.item_hta_status = item
        // },
        overview_regulatory_status,
        modifyPage
    },
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>
 